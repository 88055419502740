import React, {Component} from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import URL from 'url-parse'
import {SpotIllustration, A, Button} from '@rebrandly/styleguide'
import LinkGalleryContent from './LinkGalleryContent'
import CSSVariableApplicator from './CSSVariableApplicator'
import AlertPanel from './AlertPanel'
import {fetchMetadata, fetchAppInfo} from './utils/api'

import './App.css'

class App extends Component {
  state = {
    isLoading: false,
    userInfo: get(window, 'linkgallery.metadata', {}),
    links: [],
    theme: {},
    socials: {}
  }

  componentDidMount() {
    if (!isEmpty(this.state.userInfo)) {
      this.cleanUri()

      this.setState({isLoading: true}, () =>
        this.loadAppInfo(this.state.userInfo.id)
          .then(result =>
            this.setState({
              links: get(result, 'links', []),
              socials: get(result, 'socials', {}),
              theme: get(result, 'theme', {}),
              isLoading: false
            })
          )
      )
    } else {
      const url = URL(window.location.href, true)
      const id = get(url, 'query.source') || get(url, 'pathname').slice(1)

      if (id) {
        this.loadInfo(id)
      } else {
        window.location.assign('https://link.gallery')
      }
    }
  }

  cleanUri = () => {
    if (window.location.origin.includes('localhost') 
      || window.location.origin.includes('linkgallery.rebrandly')
      || window.location.origin.includes('link.gallery')) {
      return
    }

    if (window.location.search) {
      const query = new URLSearchParams(window.location.search)
      const pathParam = query.get('path') ||
        get(
          URL(
            process.env.REACT_APP_BASE_URL + get(window, 'linkgallery.routing.uri', ''),
          true),
          'query.path'
        )

      if (pathParam) {
        window.history.replaceState({}, '', pathParam)
      } else {
        window.history.replaceState({}, '', '/' + this.getFormattedUsername(get(this.state,'userInfo.username', '')))
      }
    }
  }

  setTabTitle () {
    const { username } = this.state.userInfo
    if (username) {
      const title = document.createElement('title')
      title.innerText = `@${username} | LinkGallery`
      document.querySelector('head').appendChild(title)
    }
  }

  loadAppInfo (id) {
    return fetchAppInfo(id)
      .then(result => {
        let theme = {}

        if (result.themes) {
          theme = result.themes.find(t => t.selected)
        } else if (result.theme) {
          theme = result.theme
        }

        return {
          links: get(result, 'links', []),
          socials: get(result, 'socials', {}),
          theme,
        }
      })
  }

  loadInfo (id) {
    this.setState({isLoading: true}, () => Promise.all([fetchMetadata(id), this.loadAppInfo(id)])
      .then(results => {
        this.setState({
          userInfo: results[0],
          links: get(results[1], 'links', []),
          socials: get(results[1], 'socials', {}),
          theme: get(results[1], 'theme', {}),
          isLoading: false
        }, () => {
          this.cleanUri()
          this.setTabTitle()
        })
      })
    )
  }

  getFormattedUsername = username => {
    return username.replace('.', '-')
  }

  render() {
    const gradientStart = get(this.state, 'theme.background.gradientStart', '')
    const gradientEnd = get(this.state, 'theme.background.gradientEnd', '')
    const image = get(this.state, 'theme.background.image')

    const background = image
      ? `url(${image})`
      : 'linear-gradient(var(--background-gradient-start), var(--background-gradient-end))'

    const theme = {
      '--background': background,
      '--background-gradient-start': gradientStart,
      '--background-gradient-end': gradientEnd,
      '--text-color': get(this.state, 'theme.text.color', ''),
      '--text-font': get(this.state, 'theme.text.font', '')
    }

    if (!this.state.userInfo.bioLink && !this.state.isLoading) {
      return (
        <AlertPanel
          size='md'
          illustration={<SpotIllustration name='ill-spot-no-results'/>}
          title='No bio link found for the account ID'
          button={
            <div>
              <A href='https://link.gallery'>
                <Button size='lg' label='Go to LinkGallery'/>
              </A>
            </div>
          }
        />
      )
    }

    return (
      <div>
        <CSSVariableApplicator variables={theme}>
          <LinkGalleryContent
            isLoading={this.state.isLoading}
            links={get(this.state, 'links', [])}
            socials={get(this.state, 'socials', {})}
            username={{
              value: get(this.state.userInfo, 'username'),
              display: get(this.state.theme, 'username.display', true)
            }}
            avatar={{
              src: get(this.state.userInfo, 'imageUri'),
              display: get(this.state.theme, 'avatar.display', true),
              anchor: get(this.state.userInfo, 'customAnchor', {})
            }}
            transparentButtons={get(this.state.theme, 'buttons.transparent', false)}
            roundedCorners={get(this.state.theme, 'buttons.borderRadius', true)}
            hideWatermark={get(this.state.userInfo, 'watermark.hide', false)}
          />
        </CSSVariableApplicator>
      </div>
    )
  }
}

export default App
